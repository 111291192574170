<template>
  <card-list>
    <div class="row mt-4">
      <div class="col-sm-12 col-md-2">
        <label class="d-inline-flex align-items-center">
          <span class="d-none d-sm-inline">Mostrar</span>
          <b-form-select
            class="ml-2 mr-1"
            v-model="paginacao.itensPorPagina"
            size="sm"
            :options="$tableItens"
            @input="getSearch"
          ></b-form-select>
        </label>
      </div>
      <div class="col-sm-12 col-md-4">
        <label class="d-inline-flex align-items-center">
          Hospital:
          <b-form-input
            v-model="filter.hospital"
            v-debounce:450="getSearch"
            type="search"
            class="ml-2 form-control form-control-sm ms-2 "
          ></b-form-input>
        </label>
      </div>
      <div class="col-sm-12 col-md-2">
        <label class="d-inline-flex align-items-center">
          Lote:
          <b-form-input
            v-model="filter.lote"
            v-debounce:450="getSearch"
            type="number"
            class="ml-2 form-control form-control-sm ms-2 "
          ></b-form-input>
        </label>
      </div>
      <div class="col-sm-12 col-md-4">
        <div class="d-inline-flex align-items-center">
          <label class=" d-inline-flex align-items-center">
            Período:&nbsp;&nbsp;
            <date-picker
              v-model="filter.periodoStart"
              :first-day-of-week="1"
              lang="pt-BR"
              format="DD/MM/YYYY"
              @change="getSearch()"
            ></date-picker>
          </label>
          <span class="mx-1 pl-1 pr-1"> a </span>
          <label class="d-inline-flex align-items-center fw-normal">
            <date-picker
              v-model="filter.periodoEnd"
              :first-day-of-week="1"
              lang="pt-BR"
              format="DD/MM/YYYY"
              @change="getSearch()"
              class="ml-2 "
            ></date-picker>
          </label>
        </div>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-sm-12 col-md-6">
        <label class="d-inline-flex align-items-center">
          <span class="d-block">Tipo Guia:</span>
          <div v-for="atribuicao in tiposGuiaFiltro" :key="atribuicao.value" class="ms-2">
            <input type="radio" class="form-check-input me-1" :id="`tipo-guia-${atribuicao.value}`"
              v-model="filter.tipoGuiaId" :value="atribuicao.value" @change="updateTipoAtendimento() && getSearch()" />
            <label class="form-check-label" :for="`tipo-guia-${atribuicao.value}`">
              {{ atribuicao.label }}
            </label>
          </div>
        </label>
      </div>
      <f-selectsearch inline
        label="Tipo Atendimento:"
        name="tipoAtendimentoIds"
        ref="tipoAtendimentoIds"
        v-model="filter.tipoAtendimentoIds"
        valueField="codigo"
        :multiple="true"
        :cols="6"
        :searchFunction="listarTipoAtendimentoPorGuiaId"
        :searchAllOptions="true"
        :removeOptionsOnSelect="false"
        @select="addTipoAtendimento"
      />
    </div>
    <div class="row mb-2">
      <div class="col-sm-12 col-md-6">
        <label class="d-inline-flex align-items-center">
          <span class="d-block">Status:</span>
          <div v-for="status in statusList" :key="status.value" class="ms-2">
            <input
              type="radio"
              class="form-check-input me-1"
              :id="`status-${status.value}`"
              v-model="filter.status"
              :value="status.value"
              @change="getSearch()"
            />
            <label class="form-check-label" :for="`status-${status.value}`">
              {{ status.label }}
            </label>
          </div>
        </label>
      </div>
      <div class="col-sm-12 col-md-4">
        <label class="d-inline-flex align-items-center">
          <span class="d-block">Lotes:</span>
          <div v-for="atribuicao in atribuicaoList" :key="atribuicao.value" class="ms-2">
            <input
              type="radio"
              class="form-check-input me-1"
              :id="`atribuicao-${atribuicao.value}`"
              v-model="filter.apenasMeusLotes"
              :value="atribuicao.value"
              @change="getSearch()"
            />
            <label class="form-check-label" :for="`atribuicao-${atribuicao.value}`">
              {{ atribuicao.label }}
            </label>
          </div>
        </label>
      </div>
      <div class="col d-flex justify-content-end">
        <b-button
          pill
          variant="success"
          v-authorize="['lotesScLotesRemoverGuia', 'lotesScLotesReativarGuia']"
          @click="openModalRepresentante()"
        >
          <i class="uil uil-file-graph"></i>
          Relatório de guias removidas
        </b-button>
      </div>
    </div>

    <div class="table-responsive">
      <b-table 
          striped hover small 
          :items="items" 
          :busy="loading"
          :fields="colunas">

        <template #table-busy>
          <ui-loading></ui-loading>
        </template>

        <template #cell(valorTotal)="data">
          {{ data.item.valorTotal | toCurrency }}
        </template>
        <template #cell(valorGlosa)="data">
          {{ data.item.valorGlosa | toCurrency }}
        </template>
        <template #cell(status)="data">
          {{ translateStatusLote(data.item.status) }}
        </template>

        <template #cell(action)="data">
          <b-button-group size="sm">
            <b-button
              variant="outline-primary"
              title="ver"
              @click="$router.push({name: 'faturamento.lotes.detalhe', params: {id: data.item.id}})"
            >
              <i class="uil uil-eye icon-size"></i>
            </b-button>

            <b-button v-if="data.item.status != 'AUDITADO'"
              variant="outline-primary"
              title="auditar"
              v-authorize="'lotesScLotesAuditar'"
              @click="$router.push({name: 'faturamento.lotes.auditar', params: {id: data.item.id}})"
            >
              <i class="uil uil-pen icon-size"></i>
            </b-button>

            <b-button v-if="data.item.status == 'AUDITADO' && isUserAdm"
              variant="outline-primary"
              title="reabrir auditoria"
              v-authorize="'lotesScLotesAuditar'"
              @click="reabrirAuditoria(data.item.id)"
            >
              <i class="uil uil-sync-exclamation icon-size"></i>
            </b-button>
          </b-button-group>
        </template>

      </b-table>
    </div>
    <div class="row">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-end">
          <ul class="pagination pagination-rounded">
            <!-- pagination -->
            <b-pagination
              v-model="paginacao.pagina"
              :total-rows="paginacao.TotalPaginas"
              :per-page="1"
              @input="getResults"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>


    <!-- o v-model não está funcionando direito, o código abaixo vai controlar qnd ele deve ser aberto -->
    <!-- nem no próprio vue-bootstrap está funcionando: https://bootstrap-vue.org/docs/components/modal#prevent-closing -->
    <b-modal
      title="Relatório de guias removidas"
      :hideHeaderClose="true"
      :centered="true"
      ok-only
      v-model="isModalRelGuiasRemovidasOpen"
    >
      <div class="row">
        <div class="col-12">
          <div class="d-inline-flex align-items-center">
            <label class=" d-inline-flex align-items-center">
              Período:&nbsp;&nbsp;
              <date-picker
                v-model="modalRelGuiasRemovidas.periodoStart"
                :first-day-of-week="1"
                lang="pt-BR"
                format="DD/MM/YYYY"
              />
            </label>
            <span class="mx-1 pl-1 pr-1"> a </span>
            <label class="d-inline-flex align-items-center fw-normal">
              <date-picker
                v-model="modalRelGuiasRemovidas.periodoEnd"
                :first-day-of-week="1"
                lang="pt-BR"
                format="DD/MM/YYYY"
                class="ml-2 "
              />
            </label>
          </div>
        </div>

        <div class="col-12">
          <label class="d-flex align-items-center">
            Convênio:
            <b-form-input
              v-model="modalRelGuiasRemovidas.convenio"
              class="ml-2 form-control ms-2 flex-grow-1"
            />
          </label>
        </div>

        <div class="col-12">
          <label class="d-flex align-items-center">
            Lote:
            <b-form-input
              v-model="modalRelGuiasRemovidas.lote"
              type="number"
              class="ml-2 form-control ms-2 "
            />
          </label>
        </div>

        <div class="col-12">
          <label class="d-flex align-items-center flex-column flex-sm-row">
            <span class="d-block">Tipo Guia:</span>
            <div v-for="atribuicao in tiposGuiaFiltro" :key="atribuicao.value" class="ms-2">
              <input
                :id="`rel-guia-tipo-guia-${atribuicao.value}`"
                type="radio"
                class="form-check-input me-1"
                v-model="modalRelGuiasRemovidas.tipoGuiaId"
                :value="atribuicao.value"
              />
              <label class="form-check-label" :for="`rel-guia-tipo-guia-${atribuicao.value}`">
                {{ atribuicao.label }}
              </label>
            </div>
          </label>
        </div>

      </div>

      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="info"
            size="sm"
            class="me-2"
            @click="() => { isModalRelGuiasRemovidasOpen = false; }"
          >
            Cancelar
          </b-button>
          <b-button
            variant="success"
            size="sm"
            class="me-2"
            @click="solicitarRelGuiasRemovidas()"
          >
            Baixar
          </b-button>
        </div>
      </template>

    </b-modal>

  </card-list>

</template>

<script>
import CardList from "@/components/Ui/CardList";
import FSelectsearch from '@/components/Form/SelectSearch';
import {
  getAllLotes, reabrirAuditoriaLote, listarTipoGuia, listarTipoAtendimento, downloadRelGuiasRemovidas
} from "../../../services/faturamento.service";
import { translateStatusLote, statusLoteRadios } from '../../../utils/mixins.statuses.js'
import { messageConfirm, messageSuccess, messageError } from "../../../utils/messages";
import { trataErro } from "../../../utils/tratar.retornos";
import { ATRIBUICAO } from "../../../services/atribuicao.service";

export default {
  components: {
    CardList, FSelectsearch
  },
  data() {
    return {
      isUserAdm: false,
      translateStatusLote,
      filter: {
        hospital: '',
        lote: '',
        periodoStart: '',
        periodoEnd: '',
        status: 'TODOS',
        apenasMeusLotes: false,
        tipoGuiaId: '',
        tipoAtendimentoIds: [],
      },
      colunas: [
        { key: "lote", label: "Lote" },
        { key: "hospital", label: "Hospital" },
        { key: "convenio", label: "Convênio" },

        { key: "valorTotal", label: "Valor Total" },
        { key: "valorGlosa", label: "Glosa" },
        { key: "qtdGuias", label: "Qtd. Guias" },
        { key: "auditadoPor", label: "Auditado Por" },
        { key: "status", label: "Status" },
        { key: "action", label: "Ações", thClass: 'text-center', tdClass: 'text-center text-nowrap' },
      ],
      periodo: {},
      items: [],
      paginacao: {
        pagina: 1,
        itensPorPagina: this.$tableItensDefault,
        TotalPaginas: 1,
        totalRegistros: 1,
      },
      ordenacao: {},
      loading: true,
      statusList: [],
      atribuicaoList: [
        { value: false, label: 'Todos' },
        { value: true, label: 'Atribuídos a mim' }
      ],
      tiposGuiaFiltro: [],

      isModalRelGuiasRemovidasOpen: false,
      optionsRepresentacao: [
        { text: 'Usar assinatura digital', value: 'S' },
        { text: 'Não usar assinatura digital', value: 'N' }
      ],
      modalRelGuiasRemovidas: {
        periodoStart: '',
        periodoEnd: '',
        convenio: '',
        lote: '',
        tipoGuiaId: '',
      },

    };
  },
  mounted() {
    let user = JSON.parse(localStorage.getItem("user"));
    this.isUserAdm = user.atribuicaoId == ATRIBUICAO.ADMINISTRATIVO;

    this.statusList = statusLoteRadios(true);
    this.getSearch();
    this.pesquisarTipoGuiaFiltro();
  },
  methods: {
    async pesquisarTipoGuiaFiltro() {
      this.tiposGuiaFiltro.push({ value: '', label: 'Todos' });
      await listarTipoGuia().then(({ data }) => {
        for (let i = 0; i < data.length; i++) {
          if (!data[i].status) continue;
          this.tiposGuiaFiltro.push({ value: data[i].codigo, label: data[i].label });
        }
      });
    },
    getParam() {
      return Object.assign(
        this.filter,
        {
          paginacao: this.paginacao,
        }
      );
    },
    getSearch() {
      this.paginacao.pagina = 1;
      this.getResults();
    },
    getResults() {
      this.loading = true
      getAllLotes(this.getParam()).then(({ data }) => {
        this.items = data.resultado;
        this.paginacao.pagina = data.paginacao.pagina;
        this.paginacao.TotalPaginas = data.paginacao.totalPaginas;
        this.paginacao.itensPorPagina = data.paginacao.itensPorPagina;
        this.paginacao.totalRegistros = data.paginacao.totalRegistros;
        this.loading = false
      }).catch(err => {
        console.error(err)
      });
    },
    reabrirAuditoria(loteId) {
      messageConfirm(this, "O lote voltará para o status \"Em auditoria\". Deseja continuar?", "Deseja reabrir a auditoria?")
        .then((result) => {
          if (result.isConfirmed) {
            reabrirAuditoriaLote(loteId).then(() => {
              messageSuccess(this, 'Auditoria do lote reaberta')
              this.$router.push({
                name: 'faturamento.lotes.auditar',
                params: {
                  id: loteId
                },
              })
            }).catch((err) => {
              trataErro(err, this)
            })
          }
        })
    },
    updateTipoAtendimento() {
      this.filter.tipoAtendimentoIds = [];
      this.$refs.tipoAtendimentoIds.setInitialValue([]);
      this.$refs.tipoAtendimentoIds.asyncFind('', true);

      return true;
    },
    addTipoAtendimento(tipoAtendimento) {
      this.filter.tipoAtendimentoIds = tipoAtendimento.map(m => m.codigo);
      this.getSearch();
    },
    listarTipoAtendimentoPorGuiaId() {
      return listarTipoAtendimento(this.filter.tipoGuiaId);
    },

    openModalRepresentante() {
      this.isModalRelGuiasRemovidasOpen = true
    },
    solicitarRelGuiasRemovidas() {
      let executarDownload = true;
      if (!this.modalRelGuiasRemovidas.periodoStart) {
        messageError(this, 'Selecione uma data inicial ao período')
        executarDownload = false;
      }
      if (!this.modalRelGuiasRemovidas.periodoEnd) {
        messageError(this, 'Selecione uma data final ao período')
        executarDownload = false;
      }

      if (executarDownload) {
        this.isModalRelGuiasRemovidasOpen = false;
        //download
        this.downloadRelGuiasRemovidas()
      }
    },
    downloadRelGuiasRemovidas() {
      downloadRelGuiasRemovidas(
        this.modalRelGuiasRemovidas.periodoStart,
        this.modalRelGuiasRemovidas.periodoEnd,
        this.modalRelGuiasRemovidas.convenioIds,
        this.modalRelGuiasRemovidas.lote,
        this.modalRelGuiasRemovidas.tipoGuiaId,
        "relatorio-guias-removidas.xlsx"
      )
        .catch((err) => {
          trataErro(err, this)
        })
    },

  },
};
</script>

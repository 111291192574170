<template>
  <card-list>
    <template #dropdownItens>
      <b-dropdown-item
        href="#"
        v-if="$hasPerm('admHospCriar')"
        @click="$router.push({name: 'sistema.hospital.criar' })"
      >
        Novo hospital
      </b-dropdown-item>
    </template>

    <div class="row mt-4">
      <div class="col-md-2 col-sm-4 col-3">
        <label class="d-inline-flex align-items-center">
          <span class="d-none d-sm-inline">Mostrar</span>
          <b-form-select
            class="ms-2"
            v-model="paginacao.itensPorPagina"
            size="sm"
            :options="$tableItens"
            @input="getSearch"
          />
        </label>
      </div>
      <div class="col-md-10 col-sm-8 col-9 d-flex justify-content-end">
        <label class="d-inline-flex align-items-center">
          Filtrar:
          <b-form-input
            v-model="filter.filtrar"
            type="search"
            class="form-control form-control-sm ms-2"
            v-debounce:450="getSearch"
          />
        </label>
      </div>
    </div>
    <div class="table-responsive">
      <ui-table striped hover
        :colunas="colunas"
        :items="items"
        :ordenacao-campo.sync="ordenacao.campo"
        :ordenacao-direcao.sync="ordenacao.direcao"
        :loading="loading"
        @force-refresh="getResults"
      >
        <template #actions="row">
          <b-button
            variant="outline-primary"
            small
            title="ver"
            v-authorize="'admHospList'"
            @click="$router.push({name: 'sistema.hospital.visualizar', params: { id: row.data.id } })"
          >
            <i class="uil uil-eye icon-size"></i>
          </b-button>

          <b-button variant="outline-primary"
            title="editar"
            small
            v-authorize="'admHospEditar'"
            @click="$router.push({name: 'sistema.hospital.editar', params: { id: row.data.id } })"
          >
            <i class="uil uil-pen icon-size"></i>
          </b-button>

          <b-button variant="outline-primary"
            title="usuários"
            small
            v-authorize="'admHosp'"
            @click="$router.push({name: 'sistema.hospital.usuarios.lista', params: { id: row.data.id } })"
          >
            <i class="uil uil-users-alt icon-size"></i>
          </b-button>
        </template>
      </ui-table>
    </div>
    <div class="row">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-end">
          <ul class="pagination pagination-rounded">
            <b-pagination
              v-model="paginacao.pagina"
              :total-rows="paginacao.totalRegistros"
              :per-page="paginacao.itensPorPagina"
              @input="getResults"
            />
          </ul>
        </div>
      </div>
    </div>
  </card-list>
</template>

<script>
import CardList from "@/components/Ui/CardList";

import { getAll } from "@/services/hospital.service";
import UiTable from '../../../../components/Ui/UiTable.vue';

export default {
  page: {
    title: (store, route) => {
      return route.meta.title;
    }
  },
  components: {
    CardList,
    UiTable
  },
  data() {
    return {
      filter: {
        filtrar: ''
      },
      colunas: [
        { key: "nome", label: "Nome", sortable: true },
        { key: "status", label: "Status", thStyle: 'width: 120px;', sortable: true },
        { key: "quantidadeUsuarios", label: "Quantidade de usuários", thStyle: 'width: 190px;', sortable: true },
        { key: "action", label: "Ações", thClass: 'list-act-bar', tdClass: 'list-act-bar' },
      ],
      items: [],
      paginacao: {
        pagina: 1,
        itensPorPagina: this.$tableItensDefault,
        totalPaginas: 1,
        totalRegistros: 1,
      },
      ordenacao: {
        campo: '',
        direcao: ''
      },
      loading: true,
      ignDblReq: null
    };
  },
  mounted() {
    this.getSearch();
  },
  methods: {
    getParam() {
      return Object.assign(
        this.filter,
        {
          paginacao: this.paginacao,
          ordenacao: this.ordenacao,
        }
      );
    },
    getSearch() {
      this.paginacao.pagina = 1;
      this.getResults();
    },
    getResults() {
      this.loading = true;

      if (this.ignDblReq == null) {
        this.ignDblReq = new AbortController();
      } else {
        this.ignDblReq.abort();
      }

      getAll(this.getParam(), this.ignDblReq)
        .then(({ data }) => {
          this.items = data.resultado;
          this.paginacao.pagina = data.paginacao.pagina;
          this.paginacao.totalPaginas = data.paginacao.totalPaginas;
          this.paginacao.itensPorPagina = data.paginacao.itensPorPagina;
          this.paginacao.totalRegistros = data.paginacao.totalRegistros;
          this.ordenacao.campo = data.ordenacao.campo;
          this.ordenacao.direcao = data.ordenacao.direcao;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  }
};
</script>

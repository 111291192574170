var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "card-list",
    [
      _c("div", { staticClass: "row mt-4" }, [
        _c("div", { staticClass: "col-sm-12 col-md-2" }, [
          _c(
            "label",
            { staticClass: "d-inline-flex align-items-center" },
            [
              _c("span", { staticClass: "d-none d-sm-inline" }, [
                _vm._v("Mostrar")
              ]),
              _c("b-form-select", {
                staticClass: "ml-2 mr-1",
                attrs: { size: "sm", options: _vm.$tableItens },
                on: { input: _vm.getSearch },
                model: {
                  value: _vm.paginacao.itensPorPagina,
                  callback: function($$v) {
                    _vm.$set(_vm.paginacao, "itensPorPagina", $$v)
                  },
                  expression: "paginacao.itensPorPagina"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "col-sm-12 col-md-4" }, [
          _c(
            "label",
            { staticClass: "d-inline-flex align-items-center" },
            [
              _vm._v(" Hospital: "),
              _c("b-form-input", {
                directives: [
                  {
                    name: "debounce",
                    rawName: "v-debounce:450",
                    value: _vm.getSearch,
                    expression: "getSearch",
                    arg: "450"
                  }
                ],
                staticClass: "ml-2 form-control form-control-sm ms-2 ",
                attrs: { type: "search" },
                model: {
                  value: _vm.filter.hospital,
                  callback: function($$v) {
                    _vm.$set(_vm.filter, "hospital", $$v)
                  },
                  expression: "filter.hospital"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "col-sm-12 col-md-2" }, [
          _c(
            "label",
            { staticClass: "d-inline-flex align-items-center" },
            [
              _vm._v(" Lote: "),
              _c("b-form-input", {
                directives: [
                  {
                    name: "debounce",
                    rawName: "v-debounce:450",
                    value: _vm.getSearch,
                    expression: "getSearch",
                    arg: "450"
                  }
                ],
                staticClass: "ml-2 form-control form-control-sm ms-2 ",
                attrs: { type: "number" },
                model: {
                  value: _vm.filter.lote,
                  callback: function($$v) {
                    _vm.$set(_vm.filter, "lote", $$v)
                  },
                  expression: "filter.lote"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "col-sm-12 col-md-4" }, [
          _c("div", { staticClass: "d-inline-flex align-items-center" }, [
            _c(
              "label",
              { staticClass: " d-inline-flex align-items-center" },
              [
                _vm._v(" Período: "),
                _c("date-picker", {
                  attrs: {
                    "first-day-of-week": 1,
                    lang: "pt-BR",
                    format: "DD/MM/YYYY"
                  },
                  on: {
                    change: function($event) {
                      return _vm.getSearch()
                    }
                  },
                  model: {
                    value: _vm.filter.periodoStart,
                    callback: function($$v) {
                      _vm.$set(_vm.filter, "periodoStart", $$v)
                    },
                    expression: "filter.periodoStart"
                  }
                })
              ],
              1
            ),
            _c("span", { staticClass: "mx-1 pl-1 pr-1" }, [_vm._v(" a ")]),
            _c(
              "label",
              { staticClass: "d-inline-flex align-items-center fw-normal" },
              [
                _c("date-picker", {
                  staticClass: "ml-2 ",
                  attrs: {
                    "first-day-of-week": 1,
                    lang: "pt-BR",
                    format: "DD/MM/YYYY"
                  },
                  on: {
                    change: function($event) {
                      return _vm.getSearch()
                    }
                  },
                  model: {
                    value: _vm.filter.periodoEnd,
                    callback: function($$v) {
                      _vm.$set(_vm.filter, "periodoEnd", $$v)
                    },
                    expression: "filter.periodoEnd"
                  }
                })
              ],
              1
            )
          ])
        ])
      ]),
      _c(
        "div",
        { staticClass: "row mb-2" },
        [
          _c("div", { staticClass: "col-sm-12 col-md-6" }, [
            _c(
              "label",
              { staticClass: "d-inline-flex align-items-center" },
              [
                _c("span", { staticClass: "d-block" }, [_vm._v("Tipo Guia:")]),
                _vm._l(_vm.tiposGuiaFiltro, function(atribuicao) {
                  return _c(
                    "div",
                    { key: atribuicao.value, staticClass: "ms-2" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.filter.tipoGuiaId,
                            expression: "filter.tipoGuiaId"
                          }
                        ],
                        staticClass: "form-check-input me-1",
                        attrs: {
                          type: "radio",
                          id: "tipo-guia-" + atribuicao.value
                        },
                        domProps: {
                          value: atribuicao.value,
                          checked: _vm._q(
                            _vm.filter.tipoGuiaId,
                            atribuicao.value
                          )
                        },
                        on: {
                          change: [
                            function($event) {
                              return _vm.$set(
                                _vm.filter,
                                "tipoGuiaId",
                                atribuicao.value
                              )
                            },
                            function($event) {
                              _vm.updateTipoAtendimento() && _vm.getSearch()
                            }
                          ]
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "form-check-label",
                          attrs: { for: "tipo-guia-" + atribuicao.value }
                        },
                        [_vm._v(" " + _vm._s(atribuicao.label) + " ")]
                      )
                    ]
                  )
                })
              ],
              2
            )
          ]),
          _c("f-selectsearch", {
            ref: "tipoAtendimentoIds",
            attrs: {
              inline: "",
              label: "Tipo Atendimento:",
              name: "tipoAtendimentoIds",
              valueField: "codigo",
              multiple: true,
              cols: 6,
              searchFunction: _vm.listarTipoAtendimentoPorGuiaId,
              searchAllOptions: true,
              removeOptionsOnSelect: false
            },
            on: { select: _vm.addTipoAtendimento },
            model: {
              value: _vm.filter.tipoAtendimentoIds,
              callback: function($$v) {
                _vm.$set(_vm.filter, "tipoAtendimentoIds", $$v)
              },
              expression: "filter.tipoAtendimentoIds"
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "row mb-2" }, [
        _c("div", { staticClass: "col-sm-12 col-md-6" }, [
          _c(
            "label",
            { staticClass: "d-inline-flex align-items-center" },
            [
              _c("span", { staticClass: "d-block" }, [_vm._v("Status:")]),
              _vm._l(_vm.statusList, function(status) {
                return _c("div", { key: status.value, staticClass: "ms-2" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filter.status,
                        expression: "filter.status"
                      }
                    ],
                    staticClass: "form-check-input me-1",
                    attrs: { type: "radio", id: "status-" + status.value },
                    domProps: {
                      value: status.value,
                      checked: _vm._q(_vm.filter.status, status.value)
                    },
                    on: {
                      change: [
                        function($event) {
                          return _vm.$set(_vm.filter, "status", status.value)
                        },
                        function($event) {
                          return _vm.getSearch()
                        }
                      ]
                    }
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "form-check-label",
                      attrs: { for: "status-" + status.value }
                    },
                    [_vm._v(" " + _vm._s(status.label) + " ")]
                  )
                ])
              })
            ],
            2
          )
        ]),
        _c("div", { staticClass: "col-sm-12 col-md-4" }, [
          _c(
            "label",
            { staticClass: "d-inline-flex align-items-center" },
            [
              _c("span", { staticClass: "d-block" }, [_vm._v("Lotes:")]),
              _vm._l(_vm.atribuicaoList, function(atribuicao) {
                return _c(
                  "div",
                  { key: atribuicao.value, staticClass: "ms-2" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filter.apenasMeusLotes,
                          expression: "filter.apenasMeusLotes"
                        }
                      ],
                      staticClass: "form-check-input me-1",
                      attrs: {
                        type: "radio",
                        id: "atribuicao-" + atribuicao.value
                      },
                      domProps: {
                        value: atribuicao.value,
                        checked: _vm._q(
                          _vm.filter.apenasMeusLotes,
                          atribuicao.value
                        )
                      },
                      on: {
                        change: [
                          function($event) {
                            return _vm.$set(
                              _vm.filter,
                              "apenasMeusLotes",
                              atribuicao.value
                            )
                          },
                          function($event) {
                            return _vm.getSearch()
                          }
                        ]
                      }
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "form-check-label",
                        attrs: { for: "atribuicao-" + atribuicao.value }
                      },
                      [_vm._v(" " + _vm._s(atribuicao.label) + " ")]
                    )
                  ]
                )
              })
            ],
            2
          )
        ]),
        _c(
          "div",
          { staticClass: "col d-flex justify-content-end" },
          [
            _c(
              "b-button",
              {
                directives: [
                  {
                    name: "authorize",
                    rawName: "v-authorize",
                    value: [
                      "lotesScLotesRemoverGuia",
                      "lotesScLotesReativarGuia"
                    ],
                    expression:
                      "['lotesScLotesRemoverGuia', 'lotesScLotesReativarGuia']"
                  }
                ],
                attrs: { pill: "", variant: "success" },
                on: {
                  click: function($event) {
                    return _vm.openModalRepresentante()
                  }
                }
              },
              [
                _c("i", { staticClass: "uil uil-file-graph" }),
                _vm._v(" Relatório de guias removidas ")
              ]
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "table-responsive" },
        [
          _c("b-table", {
            attrs: {
              striped: "",
              hover: "",
              small: "",
              items: _vm.items,
              busy: _vm.loading,
              fields: _vm.colunas
            },
            scopedSlots: _vm._u([
              {
                key: "table-busy",
                fn: function() {
                  return [_c("ui-loading")]
                },
                proxy: true
              },
              {
                key: "cell(valorTotal)",
                fn: function(data) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("toCurrency")(data.item.valorTotal)) +
                        " "
                    )
                  ]
                }
              },
              {
                key: "cell(valorGlosa)",
                fn: function(data) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("toCurrency")(data.item.valorGlosa)) +
                        " "
                    )
                  ]
                }
              },
              {
                key: "cell(status)",
                fn: function(data) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.translateStatusLote(data.item.status)) +
                        " "
                    )
                  ]
                }
              },
              {
                key: "cell(action)",
                fn: function(data) {
                  return [
                    _c(
                      "b-button-group",
                      { attrs: { size: "sm" } },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "outline-primary", title: "ver" },
                            on: {
                              click: function($event) {
                                return _vm.$router.push({
                                  name: "faturamento.lotes.detalhe",
                                  params: { id: data.item.id }
                                })
                              }
                            }
                          },
                          [_c("i", { staticClass: "uil uil-eye icon-size" })]
                        ),
                        data.item.status != "AUDITADO"
                          ? _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "authorize",
                                    rawName: "v-authorize",
                                    value: "lotesScLotesAuditar",
                                    expression: "'lotesScLotesAuditar'"
                                  }
                                ],
                                attrs: {
                                  variant: "outline-primary",
                                  title: "auditar"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.$router.push({
                                      name: "faturamento.lotes.auditar",
                                      params: { id: data.item.id }
                                    })
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "uil uil-pen icon-size"
                                })
                              ]
                            )
                          : _vm._e(),
                        data.item.status == "AUDITADO" && _vm.isUserAdm
                          ? _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "authorize",
                                    rawName: "v-authorize",
                                    value: "lotesScLotesAuditar",
                                    expression: "'lotesScLotesAuditar'"
                                  }
                                ],
                                attrs: {
                                  variant: "outline-primary",
                                  title: "reabrir auditoria"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.reabrirAuditoria(data.item.id)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "uil uil-sync-exclamation icon-size"
                                })
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col" }, [
          _c(
            "div",
            {
              staticClass: "dataTables_paginate paging_simple_numbers float-end"
            },
            [
              _c(
                "ul",
                { staticClass: "pagination pagination-rounded" },
                [
                  _c("b-pagination", {
                    attrs: {
                      "total-rows": _vm.paginacao.TotalPaginas,
                      "per-page": 1
                    },
                    on: { input: _vm.getResults },
                    model: {
                      value: _vm.paginacao.pagina,
                      callback: function($$v) {
                        _vm.$set(_vm.paginacao, "pagina", $$v)
                      },
                      expression: "paginacao.pagina"
                    }
                  })
                ],
                1
              )
            ]
          )
        ])
      ]),
      _c(
        "b-modal",
        {
          attrs: {
            title: "Relatório de guias removidas",
            hideHeaderClose: true,
            centered: true,
            "ok-only": ""
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "div",
                    { staticClass: "w-100" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "me-2",
                          attrs: { variant: "info", size: "sm" },
                          on: {
                            click: function() {
                              _vm.isModalRelGuiasRemovidasOpen = false
                            }
                          }
                        },
                        [_vm._v(" Cancelar ")]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "me-2",
                          attrs: { variant: "success", size: "sm" },
                          on: {
                            click: function($event) {
                              return _vm.solicitarRelGuiasRemovidas()
                            }
                          }
                        },
                        [_vm._v(" Baixar ")]
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.isModalRelGuiasRemovidasOpen,
            callback: function($$v) {
              _vm.isModalRelGuiasRemovidasOpen = $$v
            },
            expression: "isModalRelGuiasRemovidasOpen"
          }
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "d-inline-flex align-items-center" }, [
                _c(
                  "label",
                  { staticClass: " d-inline-flex align-items-center" },
                  [
                    _vm._v(" Período: "),
                    _c("date-picker", {
                      attrs: {
                        "first-day-of-week": 1,
                        lang: "pt-BR",
                        format: "DD/MM/YYYY"
                      },
                      model: {
                        value: _vm.modalRelGuiasRemovidas.periodoStart,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.modalRelGuiasRemovidas,
                            "periodoStart",
                            $$v
                          )
                        },
                        expression: "modalRelGuiasRemovidas.periodoStart"
                      }
                    })
                  ],
                  1
                ),
                _c("span", { staticClass: "mx-1 pl-1 pr-1" }, [_vm._v(" a ")]),
                _c(
                  "label",
                  { staticClass: "d-inline-flex align-items-center fw-normal" },
                  [
                    _c("date-picker", {
                      staticClass: "ml-2 ",
                      attrs: {
                        "first-day-of-week": 1,
                        lang: "pt-BR",
                        format: "DD/MM/YYYY"
                      },
                      model: {
                        value: _vm.modalRelGuiasRemovidas.periodoEnd,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.modalRelGuiasRemovidas,
                            "periodoEnd",
                            $$v
                          )
                        },
                        expression: "modalRelGuiasRemovidas.periodoEnd"
                      }
                    })
                  ],
                  1
                )
              ])
            ]),
            _c("div", { staticClass: "col-12" }, [
              _c(
                "label",
                { staticClass: "d-flex align-items-center" },
                [
                  _vm._v(" Convênio: "),
                  _c("b-form-input", {
                    staticClass: "ml-2 form-control ms-2 flex-grow-1",
                    model: {
                      value: _vm.modalRelGuiasRemovidas.convenio,
                      callback: function($$v) {
                        _vm.$set(_vm.modalRelGuiasRemovidas, "convenio", $$v)
                      },
                      expression: "modalRelGuiasRemovidas.convenio"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "col-12" }, [
              _c(
                "label",
                { staticClass: "d-flex align-items-center" },
                [
                  _vm._v(" Lote: "),
                  _c("b-form-input", {
                    staticClass: "ml-2 form-control ms-2 ",
                    attrs: { type: "number" },
                    model: {
                      value: _vm.modalRelGuiasRemovidas.lote,
                      callback: function($$v) {
                        _vm.$set(_vm.modalRelGuiasRemovidas, "lote", $$v)
                      },
                      expression: "modalRelGuiasRemovidas.lote"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "col-12" }, [
              _c(
                "label",
                {
                  staticClass:
                    "d-flex align-items-center flex-column flex-sm-row"
                },
                [
                  _c("span", { staticClass: "d-block" }, [
                    _vm._v("Tipo Guia:")
                  ]),
                  _vm._l(_vm.tiposGuiaFiltro, function(atribuicao) {
                    return _c(
                      "div",
                      { key: atribuicao.value, staticClass: "ms-2" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.modalRelGuiasRemovidas.tipoGuiaId,
                              expression: "modalRelGuiasRemovidas.tipoGuiaId"
                            }
                          ],
                          staticClass: "form-check-input me-1",
                          attrs: {
                            id: "rel-guia-tipo-guia-" + atribuicao.value,
                            type: "radio"
                          },
                          domProps: {
                            value: atribuicao.value,
                            checked: _vm._q(
                              _vm.modalRelGuiasRemovidas.tipoGuiaId,
                              atribuicao.value
                            )
                          },
                          on: {
                            change: function($event) {
                              return _vm.$set(
                                _vm.modalRelGuiasRemovidas,
                                "tipoGuiaId",
                                atribuicao.value
                              )
                            }
                          }
                        }),
                        _c(
                          "label",
                          {
                            staticClass: "form-check-label",
                            attrs: {
                              for: "rel-guia-tipo-guia-" + atribuicao.value
                            }
                          },
                          [_vm._v(" " + _vm._s(atribuicao.label) + " ")]
                        )
                      ]
                    )
                  })
                ],
                2
              )
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }